.nrlApp{
    height: auto;
    width: 100%;
}

@media (max-width: 768px) {
    .mainPanel {
        display: grid;
        padding: 0;
        max-width: 100vw !important;
        margin: 0;
    }

    .leftPanel {
        width: auto;
        padding-top: 1.5rem;
    }

    .rightPanel {
        width: auto;
    }

    .navbar-brand {
        font-size: 1rem !important;
    }

    .hamburgerMenu {
        width: 1rem !important;
    }

    .navbar-toggler {
        padding-left: 0;
    }

}