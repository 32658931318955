.buttonRow {
    margin-top: 2rem !important;
    float: right;
}

.row {
    margin-top: 1rem;
}

.titleWatchers{
    padding-top: 1rem;
}

.addWatchers {
    float: right;
    color: #197a56;
}

.col-small{
    display: contents;
}

.removeWatchers{
    float: right;
    color: #696969;
    cursor: pointer;
    padding-top: 2.5rem;
    
    
}


.watchers{
    width: 1055px;
}
.watchersC{
    width: 1080px;
}

.addWatchers:hover{
    color: #0e3e1b;
    cursor: pointer;
}

.addWatchers p {
    float: inline-end;
    margin-left: 10px;
}
.deleteIcon {
    width: 1080px;
}
@media (max-width: 768px) {
    .col-md {
        margin-bottom: 1rem;
    }
    .deleteIcon {
        flex-wrap: wrap;
        display: inline-block;
        min-width: 100px;
    }
    .watcherRow{
        border: 1px solid #d3d3d3;
        padding: 1rem;
        width: auto;
    }

    .removeWatchers{
        float: inherit;
        justify-content: center;
        color: #696969;
        cursor: pointer;
        padding-top: 0rem;
        width: auto;
        
    }

    .watcherActions{
        display: inline-flex;
        justify-content: center;
    }

    .modal{
        position: relative;
    }
}