bcg-people-profile {
    --bs-color: var(--bs-secondary);
    --bs-color-hover: #5bb9a2;
}

.navbar-brand {
    margin-left: 2rem;
}

nav.navbar {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    border-bottom: 1px solid #d3d3d3;
    z-index: 9999;
}

.rightLinks {
    color: #696969;
    padding-right: 2rem !important;
    text-decoration: none;
}

.userInfo {
    margin-top: 0.4rem;
    color: #696969;
    margin-right: 2rem !important;
    margin-left: 2rem !important;
    text-decoration: none;
}

.hamburgerMenu {
    margin-right: 2rem !important;
}


@media (max-width: 768px) {

    .navBarContainer {
        display: none;
    }

    .hamburgerLinks {
        color: #696969;
        padding-left: 2rem !important;
        text-decoration: none;
    }

    .userInfo {
        display: none;
    }
}