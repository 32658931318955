@import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';

html, body, #root {
    height: 100vh;
}

.spinner-border-lg {
    position: relative;
    top: 50%;
    left: 50%;
    width: 3rem;
    height: 3rem;
}
div{
    box-sizing: border-box;
}

@media (max-width: 768px) {
    body.modal-open {
        overflow: hidden;
        padding-right: 0 !important;
    }
}