.title {
    font-size: 22px;
}

.contentPanel {
    margin-left: 20rem;
    padding-top: 3rem;
}

@media (max-width: 768px) {

    .contentPanel {
        margin-left: 1.25rem;
    }
}