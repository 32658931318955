.new-ui.nav-tabs .nav-item .nav-link {
    border-bottom: 2px solid #B1B1B1;
    display: block;
}

.new-ui.nav-tabs .nav-item .nav-link::before {
    content: '';
    display: block;
    position: relative;
    left: 50%;
    right: 50%;
    bottom: -3.95em;
    transform: translate(-50%, -50%);
    width: 12px; /* Circle diameter */
    height: 12px; /* Circle diameter */
    border-radius: 50%; /* Makes it a circle */
    border: 1px solid #B1B1B1;
}

.new-ui.nav-tabs .nav-link.active::before {
    background-color: #2D7A55; /* Circle color */
    border: none;
}

.new-ui .nav-link {
    padding: 1.6em;
}

.tabs-mobile.new-ui {
    margin-bottom: 3em !important;
}