.form-check-group .form-check:hover {
    background-color: lightgray;
}

.dropdown-filter + div .btn-secondary {
    display: none;
}

.search-container {
    margin-bottom: 1em;
    width: 295px;
}

.id-cell {
    display: flex;
    gap: 5px;
    align-items: baseline;
}

.id-cell .offline-icon {
    fill: currentColor;
    color: #BB3F3F;
}

tr > th {
    box-shadow: none !important;
}

.table {
    max-height: 30vh !important;
}

.new-request {
    padding-left: 1em;
    margin-bottom: 1em;
    font-size: 14px;
}

.btn-filter-icon-not-inuse{
    opacity: 1 !important;
}

@media screen and (max-width: 768px) {
    .search-container {
        width: 100%;
    }
}