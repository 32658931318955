.searchBar {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 0.5em;
    padding: 1em 1em;
    border: solid 1px transparent;
    cursor: text;
}

.searchBar:hover {
    border: 1px solid #B1B1B1;
}

.searchInput {
    border: none;
    color: #323232;
    flex-grow: 1;
}

.searchInput::placeholder {
    color: #B1B1B1;
    font-size: 14px;
}

.searchInput:focus {
    border: none;
    outline: none;
}

.searchButton {
    border: none;
    cursor: pointer;
    color: #B1B1B1;
}

.searchButton:hover {
    border: none;
    cursor: pointer;
    color: #323232;
}

.clear {
    margin-left: auto;
    color: #197A56;
    display: none;
    cursor: pointer;
}

.searchBar:hover > .searchButton {
    color: #323232;
}

.searchBar:hover > .clear {
    display: initial;
}

