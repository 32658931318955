.gridCard {
    width: 24rem !important;
    /* border: 1px solid #d3d3d3 !important; */
    box-shadow:inset;
    position: inherit;
}


@media (max-width: 768px) {
    .gridCard {
        width: auto !important;
        border-top: 1px solid #d3d3d3;
        position: inherit;
    }

    .card-title {
        display: -webkit-box;
        max-width: 60vw;
        width: 60vw;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}