.viewItem {
    border-bottom: 1px solid #D4D4D4;
    padding: 4px 2em 4px 1em;
    display: flex;
    gap: 1em;
    align-items: center;
    box-sizing: border-box;
}

.number {
    margin-left: auto;
    font-size: 30px;
    color: #696969;
}

.label {
    padding: 4px;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .viewItem {
        font-size: 14px;
        padding:10px;
        box-sizing: border-box;
    }
    
    .viewItem:first-child{
        border-top: 1px solid #D4D4D4;
    }

    .label {
        text-transform: none;
    }
    
    .number {
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 14px;
    }

}