.request-container {
    padding-top: 1rem;
}

.request-contentPanel {
    display: flex;
    margin-left: 2rem;
    padding-top: 1rem;
}

.request-leftPanel {
    width: 75%;
    height: 100%;
    padding-right: 2rem;
}

.request-rightPanel {
    width: 25%;
    height: 100%;
}

.request-headerSection {
    margin-bottom: 1rem;
}

.request-headerSection p {
    font-weight: bold;
}

.request-headerSection button {
    display: none;
}


.row {
    margin-top: 1rem;
}

.request-button-row {
    margin-top: 0.5rem !important;
}


@media (max-width: 768px) {

    .request-contentPanel {
        display: grid;
        margin-left: 1rem;
        padding-right: 1.5rem;
    }

    .request-leftPanel {
        width: 100%;
        padding-top: 1.5rem;
    }

    .request-rightPanel {
        width: 100%;
    }

    .request-headerSection {
        display: flex;
    }

    .request-headerSection button {
        display: none;
    }

    .request-pageInfo {
        display: none;
    }

    .col-md {
        margin-bottom: 1rem;
    }
}