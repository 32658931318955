.container {
    max-width: 667px;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
}

.statusItemsContainer {
    display: flex;
    flex-direction: column;
}


.title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #535353;
    font-weight: bold;
    text-transform: uppercase;
}


@media screen and (max-width: 768px) {
    .title {
        font-weight: normal;
        text-transform: none;
    }

    .container {
        padding-left: 10px;
    }

    .statusItemsContainer {
        margin-top: 2em;
    }
}
