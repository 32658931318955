.container {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.requestHeaderSection {
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex-grow: 1;
    width: 100%;
}

.inValidDate {
    color: #d82216;
    font-size: .75rem;
}

.inValidDateControl {
    border-color: #d82216;
}

.modalClose {
    border: none;
    background-color: white;
    font-size: x-large;
    font-weight: bold;
    padding-left: 405px;
    padding-inline-start: 0px;
    padding-inline-end: 0px;

}

.headerFont {
    font-weight: bold;
}

.padLeft {
    /* padding-right: 10px; */
    float: right;
}

.padNote {
    /* padding-left: 618px; */
    float: right;
    /* font-size: small; */

}

.disableChek {
    color: #808080;
}

.textAcknowledge {
    height: 10px;
    font-style: italic;
    color: rgb(225, 18, 18);
    font-size: small;
}

.padAcknowledge {
    padding-right: 57px;
}

.padCAcknowledge {
    padding-right: 76px;
}

.floatElement {
    float: right;
    line-height: 1.1;
}

.floatRElement {
    float: right;
}

.floatLElement {
    float: left;

}

.requestHeaderSection button {
    display: none;
}

.request-button-row {
    margin-top: 0.5rem !important;
}

.formGroup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5em;
}
.inlineFormGroup {
    display: flex;
    gap: 1.5em;
}

.loading-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 40%;
    z-index: 9999;
}

@media (max-width: 768px) {
    .content {
        display: grid;
        margin-left: 1rem;
        padding-right: 1.5rem;
    }

    .inlineFormGroup {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }
    
    .requestHeaderSection {
        display: flex;
        margin-top: 20px;
    }

    .requestHeaderSection button {
        display: none;
    }

    .request-pageInfo {
        display: none;
    }

    .col-md {
        margin-bottom: 1rem;
    }

    .floatRElement {
        float: none;
        padding-bottom: 60px;
    }
}