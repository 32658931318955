.dropdown-filter + div .btn-secondary {
    display: none !important;
}

.cards {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.filter {
    width: 100%;
    box-sizing: border-box;
}