.accordion-item{
    --bs-accordion-bg: rgb(215, 248, 213);
}

.accordion .accordion-button.collapsed{
    border-top: 1px solid lightgrey;
}

.accordion .accordion-button:not(.collapsed){
    border-top: 2px solid rgb(159, 246, 159);
}

.accordion{
    --bs-accordion-border-width: 0px;
    --bs-accordion-btn-icon-width: 12px;
}

.accordion .accordion-button {
    font-size: 16px;
    padding-right: 8px;
}

.accordion .accordion-body {
    font-size: 16px;
}