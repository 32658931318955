.container {
    background-color: #F2F2F2;
    min-height: 493px;
    height: 90%;
    max-width: 300px;
    padding: 2em;
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1em;
}

@media screen and (max-width: 768px) {
    .container{
        background-color: white;
        padding: 0 1em 1em;
        min-height: auto;
    }
}