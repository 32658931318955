td:first-child {
    padding-right: 1.5em;
}

.trashIcon {
    vertical-align: middle;
    width: 1.5em !important;
}

@media (max-width: 768px) {

    td:first-child {
        padding-right: 0.5em;
    }
}