.container{
    padding-top: 1rem;
}
.sorted{
    color: #787878;    
    font-size: small;
}
.borderContainer{
    border-color: #f2f2f2;
    border-style: solid;
    width: 410px;
    overflow: auto;
    white-space: nowrap;
    
}
.deleteAcknowledgement{
    float: right;
    color: #696969;
    cursor: pointer;
    padding-top: 2.5rem;
    padding-left: 0.5rem;
}
.labelField{
    font-size: small;
}
.labelManual{
    font-size: small;
    font-style:italic
}

.contentPanel{
    display: flex;
    margin-left: 2rem;
    padding-top: 1rem;
}

.disableControl
{
    color:#787878;
    background-color: #f2f2f2;
}

.disableButton
{
    background-color: #f1eeea;
    pointer-events: none;
    color: #b1b1b1;
}
.leftPanel {
    width: 75%;
    height: 100%;
    padding-right: 2rem;
}

.headerSection{
    margin-bottom: 1rem;
}

.headerSection p{
    font-weight: bold;
}

.headerSection button{
    display: none;
}

@media (max-width: 768px) {
    .contentPanel {
        display: grid;
        margin-left: 1rem;
        padding-right: 1.5rem;
    }

    .borderContainer{
        width:auto
    }
    .labelField{
        font-size: small;
    }

    .leftPanel {
        width: 100%;
        padding-top: 1.5rem;
    }

    .headerSection{
        display: flex;
    }

    .headerSection button{
        display: inline-block;
        margin-left: auto;
    }

    .col-md{
        margin-bottom: 1rem;
    }
}