.btn {
    white-space: nowrap;
    text-transform: uppercase;
    box-sizing: border-box;
}

.btnRound {
    border-radius: 2em !important;
    padding: 0.2em 2em 0.09em !important;
    display: flex;
    flex-flow: row nowrap;
    gap: 1em !important;
    align-items: center;
    min-height: 2.4em;
    justify-content: center;
}

.btnRound > svg {
    margin-bottom: 0.05em;
}

.btnRound:disabled {
    color: grey !important;
    border-color: grey !important;
}