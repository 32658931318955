.accordion {
    border: none !important;
    box-shadow: none !important;

    --bs-accordion-border-active-color: transparent !important;
    --bs-accordion-border-color: transparent !important; /* Default border color */
    --bs-accordion-border-hover-color: transparent !important;
    --bs-accordion-body-color: #696969 !important; /* Font  color */
    --bs-accordion-btn-bg: #F2F2F2 !important; /* Button background color */
}

.accordion:hover {
    --bs-accordion-bg: #e8e5e5 !important;
    --bs-accordion-btn-bg: #E8E5E5 !important; /* Button background color */
}

.accordionHeader {
    border: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
    border-top: 1px solid #E3E9EE !important;
    border-bottom: 1px solid #E3E9EE !important;
}

.accordionItem, .accordion-flush {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
}

.accordionBody {
    border: none !important;
    box-shadow: none !important;
    background-color: white !important;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 1.2em !important;
    box-sizing: border-box;
    border-radius: 8px;
}

.chip {
    background-color: white !important;
    border: 1px solid #696969 !important;
    color: #7C878E !important;
    width: fit-content;
}

@media screen and (max-width: 768px) {
    .accordion,  .accordion:hover {
        --bs-accordion-bg: white !important;
        --bs-accordion-btn-bg: white !important;
    }
}