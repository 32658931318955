.request-container {
    margin-top: 2em;
}

.project-label {
    font-weight: normal;
}

.acknowledgements-container {
    /* margin-top: 4rem; */
}

.loading-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 40%;
    z-index: 9999;
}

@media (max-width: 768px) {
    .project-label {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start !important;
    }
}