.home {
    margin-top: 1.5rem;
}

.grids {
    padding-top: 1.5rem;
}

button.active {
    border-bottom: solid green !important;
}

.newRequestButton {
    float: inline-end;
}

.nav-link:focus{
    isolation: auto !important;
}

.padding{
    padding-top: 20px;
}

@media (max-width: 768px) {
    .tabs-mobile {
        border: none;
        flex-wrap: nowrap;
    }

    .home {
        margin-top: 0.5rem;
    }
    
}