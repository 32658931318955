.request-container {
    padding-top: 1rem;
}

.request-contentPanel {
    display: flex;
    margin-left: 2rem;
    padding-top: 1rem;
}

.inValidDate {
    color: #d82216;
    font-size: .75rem;
}

.inValidDateControl {
    border-color: #d82216;
}

.modalClose {
    border: none;
    background-color: white;
    font-size: x-large;
    font-weight: bold;
    padding-left: 405px;
    padding-inline-start: 0px;
    padding-inline-end: 0px;

}
.headerFont{
    font-weight: bold;
}
.padLeft{
    /* padding-right: 10px; */
    float: right;
}
.padNote{
    /* padding-left: 618px; */
    float: right;
    /* font-size: small; */
    
}
.disableChek{
    color: #808080;
}
.textAcknowledge{
    height: 10px;
    font-style:italic;
    color: rgb(225, 18, 18);
    font-size: small;
}
.padAcknowledge{
    padding-right: 57px;
}
.padCAcknowledge{
    padding-right: 76px;
}
.floatElement{
    float: right;
    line-height: 1.1;
}
.floatRElement{
    float: right;
}
.floatLElement{
    float: left;
    
}

.request-leftPanel {
    width: 75%;
    height: 100%;
    padding-right: 2rem;
}

.request-rightPanel {
    width: 25%;
    height: 100%;
}

.request-headerSection {
    margin-bottom: 1rem;
}
.download
{
    background: url(../../../images/Download.png);
}
.request-headerSection p {
    font-weight: bold;
}

.request-headerSection button {
    display: none;
}


.row {
    margin-top: 1rem;
}

.request-button-row {
    margin-top: 0.5rem !important;
}


@media (max-width: 768px) {

    .request-contentPanel {
        display: grid;
        margin-left: 1rem;
        padding-right: 1.5rem;
    }

    .request-leftPanel {
        width: 100%;
        padding-top: 1.5rem;
    }

    .request-rightPanel {
        width: 100%;
    }

    .request-headerSection {
        display: flex;
        margin-top: 20px;
    }

    .request-headerSection button {
        display: none;
    }

    .request-pageInfo {
        display: none;
    }

    .col-md {
        margin-bottom: 1rem;
    }

    .floatRElement{
        float: none;
        padding-bottom: 60px;
    }
}