.acknowledgements-grid .table-compact th,
.acknowledgements-grid .table-compact td {
    padding: .75rem .25rem .75rem 1.5rem
}

.acknowledgements-grid th {
    background-color: #d4d4d4;
    border-bottom: 1px solid #f2f2f2;
}

.acknowledgements-grid .table-bordered>:not(caption) th,
.acknowledgements-grid .table-bordered>:not(caption) td {
    border: 1px solid black;
}

.cursor-pointer {
    cursor: pointer;
}

.acknowledgements-grid .table-mobile tbody::before {
    content: '';
  display: block;
  height: 1rem;
}

.acknowledgements-grid .grid-action-icon {
    color: unset;
    text-decoration: none;
}

.acknowledgements-grid .no-own-border {
    border: 0 !important;
}