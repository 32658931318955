.card {
    border: 1px solid #B1B1B1 !important;
    min-height: 0 !important;
    height: 100% !important;
    width: 100% !important;
    max-width: 98vw;
}

.badgeCard {
    width: fit-content !important;
    border-radius: 3px !important;
}

.requestCard {
    display: flex !important;
    flex-flow: column nowrap !important;
    gap: 1em !important;
}

p {
    margin: 0 !important;
}

.lead svg {
    color: #BB3F3F;
}