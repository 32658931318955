.overview {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 100px;
    gap: 50px;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .overview {
        flex-direction: column;
        padding-top: 0;
        align-items: start;
        padding-bottom: 20px;
    }
}