.form-label-faq {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.newRequestButton {
    float: inline-end;
}

.container {
    padding-top: 2rem;
}

.newContainer {
    padding-top: 2rem;
    padding-right: 18px;
}