.container{
    padding-top: 1rem;
}

.contentPanelRequest{
    display: flex;
    margin-left: 2rem;
    padding-top: 1rem;
}

.leftPanel {
    width: 75%;
    height: 100%;
    padding-right: 2rem;
}


@media (max-width: 768px) {

    .contentPanelRequest {
        display: grid;
        margin-left: 1rem;
        padding-right: 1.5rem;
    }

    .leftPanel {
        width: 100%;
        padding-top: 1.5rem;
    }

}