.acknowledgementCount {
    width: fit-content !important;
    display: inline-block !important;
    border-radius: 3px !important;
}

.viewBadge {
    justify-content: center;
    background-color: #dcf9e3;
    font-size: large;
    width: 50%;
}

.viewBadge svg {
    margin: 0.5rem;
}

.badge-BCGBlue {
    color: #fff;
    background-color: #0977D7;
}

.paginationContainer {
    display: flex;
    align-self: center !important;
    margin-top: 1em;
}

.card {
    border: 1px solid #B1B1B1 !important;
    min-height: 0 !important;
    height: 100% !important;
    width: 100% !important;
    max-width: 98vw;
}

.cards {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
}

@media (max-width: 768px) {
    .badge-BCGBlue {
        width: 10%;
        margin-right: 0.5rem;
    }

    .gridCard {
        width: auto !important;
        border-top: 1px solid #d3d3d3;
        position: inherit;
    }

    .card-title {
        display: -webkit-box;
        max-width: 60vw;
        width: 60vw;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cardBody {
        display: flex !important;
        flex-flow: column nowrap !important;
        gap: 0.2em !important;
    }
}