button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.on {
    color: green;
}

.off {
    color: #ccc;
}

@media (max-width: 768px) {
    .modal {
        top: 5rem;
    }
}