.acknowledgementCount {
    /* justify-content: center; */
    width: 30%;
    display: inline-block;
    margin: 0 auto;
}

.textCell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.offline
{
    background: url(../../../images/offline.png);
}
/* svg {
    fill: currentColor;
    color: red;
  } */
  .newColor
  {
    fill: currentColor;
    color: #BB3F3F;
  }

.viewBadge {
    justify-content: center;
    background-color: #dcf9e3;
    font-size: large;
    width: 50%;
}


    .viewBadge svg {
        margin: 0.5rem;
    }

.badge-BCGBlue {
    color: #fff;
    background-color: #0977D7;
}

.bcg-table-container {
    position: inherit !important;
}


    .bcg-table-container thead th {
        position: inherit !important;
    }

@media (max-width: 768px) {
    .badge-BCGBlue {
        width: 10%;
        margin-right: 0.5rem;
    }

    .gridCard {
        width: auto !important;
        border-top: 1px solid #d3d3d3;
        position: inherit;
    }

    .card-title {
        display: -webkit-box;
        max-width: 60vw;
        width: 60vw;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
