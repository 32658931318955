.home.new-ui {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

button.active {
    border-bottom: solid green !important;
}

.newRequestButton {
    float: inline-end;
    text-decoration: none;
}

.nav-link:focus {
    isolation: auto !important;
}

.hero-sub-component {
    background-color: #F1EEEA;
    padding-top: 1em;
    padding-bottom: 2em;
    max-width: 100%;
}

.hero-sub-content {
    padding-left: 1em;
    display: flex;
    flex-flow: column;
    gap: 1em;
}

@media (max-width: 768px) {
    .home.new-ui {
        margin: 0;
        padding-right: 8px;
        padding-left: 8px;
        width: calc(100% - 10px);
        box-sizing: border-box;
        overflow: hidden;
    }

    .hero-sub-component {
        display: none;
    }
}

.muted-link {
    color: #323232;
    text-decoration: none;
    font-size: 14px;
}

.muted-link:hover {
    color: #323232;
    text-decoration: underline;
}