.title {
    font-size: 22px;
}

.contentPanel {
    margin-left: 30rem;
    padding-top: 3rem;
}

.alert.alert-warning {
    color: #ffcf24;
    background-color: #fff;
    font-size: 20px;
    --bs-alert-border: 2px solid #ffecb5;
}

.alert.alert-warning::before {
    content: none;
}

.alert.alert-danger, .alert.alert-info, .alert.alert-success, .alert.alert-warning {
    padding-left: 1rem;
}

@media (max-width: 768px) {

    .contentPanel {
        margin-left: 1.25rem;
    }
}